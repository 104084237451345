<template>
  <div class="vi-school-overview">
    <main v-if="schoolData">
      <!-- ======= Search Section ======= -->
      <div class="web-resfound p-2">
        <button class="btn web-backbtn" v-if="isPayment == 'paidVersion'" @click="$router.go(-1)">
          <v-icon name="arrow-left" />
          <span class="bck" style="display:none">Back</span>
        </button>
        <div class="container ">
          <div class="row">
            <div class="col-md-3 text-center mt-3 imgresp">
               <img width="200" height="120" v-if="schoolData.image && schoolData.image.path"
                        :src="schoolData.image.path"
                        :alt="schoolData.accountName"
                        :title="schoolData.accountName"
                />
                <img width="200" height="120" v-else
                  :src="schoolData.image"
                  :alt="schoolData.accountName"
                  :title="schoolData.accountName"
                />
             
            </div>
            <div class="col-md-9 web-schnamecont mt-md-3 mt-sm-3">
              <h4>
                <strong
                  >{{ schoolData.accountName }} – Affiliated to
                  {{ schoolData.boardType }}</strong
                >
              </h4>
              <p>
                <span
                  ><i
                    class="fa fa-map-marker fa-lg"
                    aria-hidden="true"
                  ></i></span
                >
                {{ schoolData.street ? schoolData.street : '' }},{{ schoolData.city ? schoolData.city : '' }},{{ schoolData.district ? schoolData.district : '' }},{{ schoolData.state ? schoolData.state : '' }},{{ schoolData.pincode ? schoolData.pincode : '' }}
              </p>
              <p>
                <span><i class="fa fa-envelope" aria-hidden="true"></i></span
                ><a>{{ schoolData.email }}</a>
              </p>
              <p>
                <span
                  ><i class="fa fa-phone-square" aria-hidden="true"></i></span
                >{{ schoolData.landlineNo ? schoolData.landlineNo : ''  }}
              </p>
              <div class="web-admbtn">
                <a
                  class="btn web-custbtn web-rounded-xl h-100 btn-3 "
                  style="width:225px"
                  >{{ checkAdmissionAuvaiable > 0 ? 'Admission Open Now' : 'Admission Closed' }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="web-filterbg pl-3 pr-3 m-0" id="filtersects">
        <div class="container-fluid">
          <div class="row">
            <div class="offset-md-3 web-mobnav">
              <nav class=" vertical-align-middle scroll ml-2">
                <ul
                  v-scroll-spy-active="{ class: 'tabactive' }"
                  v-scroll-spy-link
                >
                  <li><a>Overview</a></li>
                  <li><a>Seat Availability</a></li>
                  <li><a>Admission Process</a></li>
                  <li><a>Facilities</a></li>
                  <li><a>Contact Details</a></li>
                  <li><a>Rating & Reviews</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="web-innerpagecont py-3 pl-3 pr-3">
        <div class="container-fluid">
          <div class="row web-row-flex">
            <div class="col-md-3 col-sm-6">
              <p><strong>Place for Google ad</strong></p>
            </div>
            <div class="col-md-9 col-sm-6">
              <div v-scroll-spy="{ data: 'section', offset: 120 }">
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection">
                      <img
                        src="../../assets/img/school_info_icon.svg"
                        width="99%"
                        alt="Overview"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Overview</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    
                    <p class="mt-2">
                      Unity Education Trust was founded in 2008 by visionaries
                      who wished to impart the best management education to the
                      students and enhance their employability levels. The
                      vision of the Trust is to nurture constructive leadership
                      and to provide a multi-faceted learning experience in a
                      stimulating learning environment. The school strives for
                      educational innovation and academic excellence through its
                      lively & caring community of teachers and students guided
                      by an experienced group of educationists. City
                      International School Kothrud is implementing student
                      assessment prescribed by CBSE which refers to a system of
                      school based assessment that covers all aspects of a
                      student's development.
                    </p>
                    <p>
                      It is with great pride and pleasure that we present a
                      comprehensive portrait of the City International School
                      Kothrud a progressive, child centered, co-educational
                      private school, committed to providing qualitative
                      education for its students.
                    </p>
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection">
                      <img
                        src="../../assets/img/seatsavailicn.svg"
                        width="99%"
                        alt="Seats Availability"
                        title="Seats Availability"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Seats Availability</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <table>
                      <thead>
                        <tr>
                          <th scope="col" style="text-align:center">Class Details</th>
                          <th scope="col" style="text-align:left">No. of Seats</th>
                          <th scope="col" style="text-align:left">No. of Seats Available</th>
                          <th scope="col" style="text-align:left">Admission Status</th>
                          <th scope="col" style="text-align:left">Annual Fees</th>
                          <th scope="col" style="text-align:left">Online</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr  v-for="(fee, index) in admissionDetails" :key="index">
                          <td data-label="Class Details">{{ fee.class ? fee.class.className : '' }}</td>
                          <td data-label="No. of Seats">{{ fee.noOfSeats }}</td>
                          <td data-label="No. of Seats">{{ fee.seatsAvailable }}</td>
                          <td data-label="No. of Seats">{{ fee.admissionStatus }}</td>
                          <td data-label="Total Fees">{{ fee.amount }} </td>
                          <td data-label="Apply Now">
                               <img v-if="fee.admissionStatus == 'Open'"
                                style="background-color: yellowgreen;border-radius: 30px;"
                                src="../../assets/img/handcursoricn.svg"
                                width="20%"
                                alt="apply now"
                                title="Apply Now"
                              />
                               <img v-else
                                style="background-color: red;cursor: not-allowed;border-radius: 30px;"
                                src="../../assets/img/notapply.svg"
                                width="20%"
                                alt="Not Available"
                                title="Not Available"
                              />
                             
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:6px">
                      <img
                        src="../../assets/img/admissionicn.svg"
                        width="99%"
                        alt="Vidhyaan Admission Process"
                        title="Vidhyaan Admission Process"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Admission Process</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <label class="web-custradio"
                      >Online Admission
                      <input
                        type="radio"
                        name="admission"
                        id="admon"
                        disabled
                        v-model="admissionSelect"
                        value="aOn"
                      />
                      <span class="web-checkmark"></span>
                    </label>
                    <label class="web-custradio"
                      >Offline Admission
                      <input
                        type="radio"
                        name="admission"
                        id="admoff"
                        disabled
                        v-model="admissionSelect"
                        value="aOff"
                      />
                      <span class="web-checkmark"></span>
                    </label>
                    <div
                      id="offlineadm"
                      v-show="admissionSelect === 'aOff'"
                      style="display:none;padding:8px;background:#efefef;"
                      class="mt-3"
                    >
                      Click on <a href="#contactdet">Contact School</a> for your
                      Offline admission.
                    </div>
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:6px">
                      <img
                        src="../../assets/img/facilitiesicn.svg"
                        width="99%"
                        alt="Facilities"
                        title="Facilities"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Facilities</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <p>
                      The school facility consists of not just the physical
                      structure such as plumbing, mechanical, electrical,
                      telecommunications, security, fire prevention and
                      suppression systems, and the various building systems, but
                      it also includes furnishing, materials and supplies,
                      equipment and information technology. Basic facilities we
                      have in our schools like,
                    </p>
                    <div class="web-dispflex">
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/school_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Type of School</p>
                          <span>{{ schoolData.schoolType }}</span>
                        </span>
                      </div>
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/afflilation_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Examination Board</p>
                          <span>{{ schoolData.affilliateIdToBoard | capitalize }}</span>
                        </span>
                      </div>
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/fees_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Annual Fees</p>
                          <span
                            >Start from Rs. {{ schoolData.lowestFees }}</span
                          >
                        </span>
                      </div>
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/lang_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Language of Instruction</p>
                          <span>{{ schoolData.medium }}</span>
                        </span>
                      </div>
                      <div
                        class="web-colwidth mb-4 colcent"
                        v-for="(fitem, index) in schoolData.facilities"
                        :key="index"
                      >
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            :src="`${facilitiesURL}/${fitem.icon}.svg`"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>{{ fitem.name }}</p>
                          <span>Yes</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:10px">
                      <img
                        src="../../assets/img/contactdetailsicon.svg"
                        width="99%"
                        alt="Contact Details"
                        title="Contact Details"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Contact Details</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <div class="form-row">
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="schoolItem.email">
                        <span class="web-has-float-label" style="flex-grow:0">
                          <label for="email">Email</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          <a href="mailto:info@velammal.org"
                            >{{ schoolItem.email }}</a
                          >
                        </div>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3" v-if="schoolItem.landlineNo">
                        <span class="web-has-float-label" style="margin:1px">
                          <label for="phno">Phone #</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          +91 {{ schoolItem.landlineNo }}
                        </div>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="schoolItem.website">
                        <span class="web-has-float-label" style="flex-grow:0">
                          <label for="website">Website</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          <a
                            href="http://www.velammal.org/velammal-vidhyashram-mambakkam/"
                            target="_blank"
                            >{{ schoolItem.website }}</a
                          >
                        </div>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3" v-if="schoolItem.location">
                        <span class="web-has-float-label" style="margin:1px">
                          <label for="location">Location</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          {{ schoolItem.location }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:10px">
                      <img
                        src="../../assets/img/reviewicn.svg"
                        width="99%"
                        alt="Ratings & Reviews"
                        title="Ratings & Reviews"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Ratings & Reviews</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <div class="web-dispflex rating">
                      <div class="web-colwidth">
                        <p><strong>Review by Parents</strong></p>
                        <hr />
                        <div class="web-dispflex">
                          <div>
                            <p>
                              <label>Infrastructure: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings.infrastructure"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Academics: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings.academics"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Sports: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings.sports"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Faculty: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings.faculty"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Safety: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings.safety"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                          </div>
                          <div class="web-overall">
                            <p>{{ schoolData.ratings | averageRating }}</p>
                            <p>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings | averageRating"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>(Over All)</p>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="web-colwidth">
                        <p><strong>Review by Vidhyaan</strong></p>
                        <hr />
                        <div class="web-dispflex">
                          <div>
                            <p>
                              <label>Infrastructure: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Academics: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Sports: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Faculty: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Safety: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                          </div>
                          <div class="web-overall">
                            <p>4.0</p>
                            <p>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>(Over All)</p>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div class="web-dispflex mt-3">
                      <div class="web-colwidth rating" style="border:0">
                        <h3>Write a Review</h3>
                        <span class="vi-error" v-if="rateError"
                          >Please rate all catocary</span
                        >
                        <!-- <p>
                          <label>Over All: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            class="vi-w-review"
                          />
                        </p> -->
                        <p>
                          <label>Infrastructure: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="setRating($event, 'infrastructure')"
                            class="vi-w-review"
                            v-model="infrastructure"
                          />
                        </p>
                        <p>
                          <label>Academics: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="setRating($event, 'academics')"
                            class="vi-w-review"
                            v-model="academics"
                          />
                        </p>
                        <p>
                          <label>Sports: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="setRating($event, 'sports')"
                            class="vi-w-review"
                            v-model="sports"
                          />
                        </p>
                        <p>
                          <label>Faculty: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="setRating($event, 'faculty')"
                            class="vi-w-review"
                            v-model="faculty"
                          />
                        </p>
                        <p>
                          <label>Safety: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="setRating($event, 'safety')"
                            class="vi-w-review"
                            v-model="safety"
                          />
                        </p>
                      </div>
                      <div class="web-colwidth" style="border:0">
                        <div class="form-row mt-4">
                          <div class="form-group input-group mt-md-3">
                            <span class="web-has-float-label">
                              <textarea
                                class="form-control web-form-input"
                                style="min-height:135px;resize:none"
                                id="message"
                                placeholder="Message"
                                v-model="message"
                              ></textarea>
                              <label
                                v-if="messageError"
                                class="vi-error"
                                for="message"
                                >{{ messageError }}</label
                              >
                              <label for="message" v-else
                                >Message<span class="required">*</span></label
                              >
                            </span>
                          </div>
                        </div>
                        <button
                          type="submit"
                          disabled
                          class="web-custbutton mt-3"
                          @click="submitRating"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <vi-spinner
      v-else
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="width: 100%;z-index: 111;top:0;left:0;background-color: #fff;bottom:0;right:0;"
    />
  </div>
</template>


<script>
import "vue-awesome/icons/arrow-left";
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "../../utils/secureUI";
import StarRating from "vue-star-rating";
export default {
  name: "vi-school-overview",
  components: {
    ViSpinner,
    StarRating,
  },
  props: ["swapComponent"],
  data() {
    return {
      isPayment: '',  
      section: 0,
      viLoader: true,
      schoolData: null,
      admissionSelect: 'aOn',
      infrastructure: null,
      academics: null,
      sports: null,
      faculty: null,
      safety: null,
      message: null,
      messageError: null,
      rateError: null,
      facilitiesURL: process.env.BASE_URL + "facilities",
      admissionDetails: []
    };
  },
  computed: {
    schoolItem() {
      return this.$store.getters.SchoolInfoObject;
    },
     checkAdmissionAuvaiable() {
      if(this.admissionDetails.length > 0){
        
       const statusCheck = this.admissionDetails.filter( x => x.admissionStatus == 'Open' && x.isShowSearch)
       return statusCheck.length
      }else{
        return 0
      }
      
    }
  },
  created() {
	  this.getSchoolInfo();
    this.searchSchoolDetails()
  },
  mounted(){
    window.addEventListener("scroll", this.onScroll)
  },
  methods: {
    onScroll(){
      let adfiltsect = document.getElementById("filtersects");
  
  

  if (adfiltsect) {
    
    if (
      document.body.scrollTop > 70 ||
      document.documentElement.scrollTop > 70
    ) {
      adfiltsect.classList.add("fixed-top");
      adfiltsect.style.top = "56px";
      adfiltsect.style.zIndex = "12";
    } else {
      adfiltsect.classList.remove("fixed-top");
    }
  }
    },
    getSchoolInfo() {
       let userData = secureUI.sessionGet("user");
       this.isPayment = userData.userDetails.account.version
      ViService.viGetOnly("/common/details?accountId=" + userData.userDetails.account._id)
        .then((res) => {
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);
            if (res.data) {
              this.schoolData = data;
            }
          } else {
            this.$toasted.show(res.message);
          }
        })
        .catch((e) => {
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
        });
    },
    setRating(rating, value) {
      value == "infrastructure" ? (this.infrastructure = rating) : null;
      value == "academics" ? (this.academics = rating) : null;
      value == "sports" ? (this.sports = rating) : null;
      value == "faculty" ? (this.faculty = rating) : null;
      value == "safety" ? (this.safety = rating) : null;
    },
    submitRating() {
      let userData = secureUI.sessionGet("user");
      if (!userData) {
        this.$toasted.error("Please login and do the action");
         this.$router.push('/login');
      } else {
        this.$toasted.clear();

        if (
          !this.infrastructure ||
          !this.academics ||
          !this.sports ||
          !this.faculty ||
          !this.safety
        ) {
          this.rateError = true;
        } else {
          this.rateError = null;
        }
        if (
          !message &&
          this.infrastructure &&
          this.academics &&
          this.sports &&
          this.faculty &&
          this.safety
        ) {
          let requestBody = {
            accountId: this.schoolItem._id,
            parentId: userData.userDetails._id,
            infrastructure: this.infrastructure,
            academics: this.academics,
            sports: this.sports,
            faculty: this.faculty,
            safety: this.safety,
            message: this.message,
            schoolName: "",
            category: "",
            city: "",
          };
          
          this.viLoader = true;
          ViService.viXPost("/rating/school", requestBody, userData.token)
            .then((res) => {
              this.viLoader = null;
              if (res.isSuccess) {
                this.clearMsg();
                this.$toasted.success("Rating submitted successfully");
              } else {
                this.$toasted.error(res.message);
              }
            })
            .catch((e) => {
              this.viLoader = null;
              let eData = errorLog.apiErrorLog(e);
              this.$toasted.error(eData);
            });
        }
      }
    },
    clearMsg() {
      this.infrastructure = 0;
      this.academics = 0;
      this.sports = 0;
      this.faculty = 0;
      this.safety = 0;
      this.message = null;
    },
    searchSchoolDetails(){
       let userData = secureUI.sessionGet("user");
       
       ViService.viGetOnly("/common/getSearchSchoolFeesDetail?accountId=" + userData.userDetails.account._id)
        .then((res) => {
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);
            if (res.data) {
             this.admissionDetails = data;
            }
          } else {
            this.$toasted.show(res.message);
          }
        })
        .catch((e) => {
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
        });
    },
  },
  filters: {
    averageRating: function(value) {
      if (!value) return "";
      let totalSum = 0;
      for (let key in value) {
        if (value.hasOwnProperty(key)) {
          totalSum += value[key];
        }
      }
      let val = (totalSum / 5).toFixed(2);
      return parseFloat(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.vi-r-review,
.vi-w-review {
  display: inline-block;
}
</style>
